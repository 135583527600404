<template>
  <div class="firm-management">
    <router-view></router-view>
    <div class="jobPost">
      <el-button
        class="management-button"
        @click="addManagement"
        icon="el-icon-plus"
        type="primary"
      >
        发布新职位
      </el-button>
      <el-button
        class="import"
        icon="el-icon-document-add"
        type="primary"
        plain
        @click="upLoadHandle"
      >
        职位导入
      </el-button>
    </div>
    <div class="management-table">
      <div
        class="table"
        :class="{ 'is-table': table_id === 1 }"
        @click="table_id = 1"
      >
        全部职位
      </div>
      <div
        class="table"
        :class="{ 'is-table': table_id === 2 }"
        @click="table_id = 2"
      >
        已发布
      </div>
      <div
        class="table"
        :class="{ 'is-table': table_id === 3 }"
        @click="table_id = 3"
      >
        已关闭
      </div>
      <div
        class="table"
        :class="{ 'is-table': table_id === 4 }"
        @click="table_id = 4"
      >
        发布失败
      </div>
    </div>
    <el-row class="management-main">
      <el-col
        :span="24"
        class="job-list"
        v-for="item in selectedChat"
        :key="item.shop_user_job_id"
      >
        <el-row class="list-main">
          <el-col :span="10" class="main-left">
            <div class="title">{{ item.job_title }}</div>
            <div class="subhead">
              {{ item.job_city }} | {{ item.job_age }} |
              {{ item.job_education }} | {{ item.job_salary }} |
              {{ item.job_duration }}
            </div>
          </el-col>
          <el-col :span="10" class="main-center">
            <div class="is-enable">
              {{ item.is_enable === 1 ? "已发布" : "未发布" }}
            </div>
            <div class="expiration-time">{{ item.expiration_time }}到期</div>
          </el-col>
          <el-col :span="4" class="main-right">
            <router-view></router-view>
            <el-button
              class="edit-button"
              size="small"
              type="primary"
              @click="handleEdit(item)"
              >编辑</el-button
            >
            <el-button
              class="release-button"
              size="small"
              type="primary"
              @click="handleAlter(item)"
              >{{ item.is_enable === 0 ? "发布" : "关闭" }}</el-button
            >
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 上传文件弹窗 -->
    <el-dialog
      title="批量导入"
      :visible.sync="upLoadVisible"
      width="30%"
      :before-close="upLoadHandleClose"
    >
      <div class="downLoad">
        <a
          href="https://bmbpdf.oss-cn-hangzhou.aliyuncs.com/%E8%81%8C%E4%BD%8D%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
          downLoad="模板"
          ><i class="el-icon-download"></i>导出模板</a
        >
      </div>
      <el-upload action="#" accept=".xls,.xlsx" :show-file-list="false" :http-request="httpRequest">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <em>点击上传文件</em>(请先下载模板)
        </div>
      </el-upload>
      <!-- <Upload :request="onUploadJobData" /> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  getChainJob,
  updateJobEnable,
  getJobDetails,
  uploadJobData,
} from "@/request/api";
const XLSX = require("xlsx");
// import Upload from "@/components/Upload.vue";
export default {
  // components: {
  //   Upload,
  // },
  data() {
    return {
      jobList: [],
      table_id: 1,
      upLoadVisible: false, //控制上传文件弹窗
    };
  },
  computed: {
    selectedChat() {
      if (this.table_id === 2) {
        return this.jobList.filter((ele) => ele.is_enable === 1);
      }

      if (this.table_id === 3) {
        return this.jobList.filter((ele) => ele.is_enable === 0);
      }

      if (this.table_id === 4) {
        return this.jobList.filter((ele) => ele.is_enable === 0);
      }

      return this.jobList;
    },
  },
  methods: {
    // async onUploadJobData(formData) {
    //   uploadJobData(formData).then((res) => {
    //     if (res.data.code === "1000") {
    //       this.$message({
    //         type: "success",
    //         message: "上传成功",
    //       });
    //       this.init();
    //     } else {
    //       this.$message({
    //         type: "error",
    //         message: "上传失败",
    //       });
    //     }
    //   });
    // },
    upLoadHandle() {
      this.upLoadVisible = true;
    },
    upLoadHandleClose() {
      this.upLoadVisible = false;
    },
    // 批量添加岗位
    httpRequest(options) {
      // 获取文件
      const file = options.file
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        // 只处理第一个工作表
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (JSON.stringify(jsonData[1]) !== '["职位名称","工作经验","岗位学历","薪资范围","薪资情况","招聘类型(全职、兼职)","截止时间","职位描述","职位关键词","工作省","工作市","详细地址","年龄要求","是否实习岗"]') {
          return this.$message.error('导入表格字段不匹配');
        }

        let reqData = [];
        for (let i = 2; i < jsonData.length; i++) {
          let indexCount = 0;
          reqData[i - 2] = jsonData[i].map(value => {
            indexCount++
            return value
          })

          if (indexCount < jsonData[i].length) {
            jsonData = false
          }
        }
        if (jsonData === false) {
          return this.$message.error('请完善表格信息')
        }

        this.takeItems(reqData).forEach(ele => {
          this.uploadMessage({ file: JSON.stringify(ele) }).then(() => {
            this.init();
          })
        })
        this.$message({ type: "success", message: "上传成功！" });
      }

      // 读取文件内容，触发onload事件。
      reader.readAsArrayBuffer(file)
    },
    takeItems(arr) {
      let result = []
      let chunkSize = 20

      for (let i = 0; i < arr.length; i += chunkSize) {
        // 计算本次需要取出的元素的结束索引
        let end = Math.min(i + chunkSize, arr.length)
        // 截取子数组
        result.push(arr.slice(i, end));

        // 如果已经取到最后一个子数组并且其长度小于chunkSize，则退出循环
        // 这样可以避免在最后一个子数组不足3个元素时再次循环
        if (end === arr.length && end - i < chunkSize) {
          break
        }
      }

      return result
    },
    // 批量上传接口
    async uploadMessage(reqData) {
      try {
        const res = await uploadJobData(reqData)
        if (res.status !== 200 || res.data.code !== "1000") return this.$message.error(res.data.msg)
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message)
      }
    },
    // 发布
    async handleAlter(item) {
      let postData = {
        shop_user_job_id: item.shop_user_job_id,
        is_enable: item.is_enable ? 0 : 1,
      };
      const res = await updateJobEnable(postData);
      if (res.data.code === "1000") {
        this.$message.success("关闭成功");
        this.init();
      } else {
        this.$message.error(res.data.msg);
      }
      // if (res.status !== 200 || res.data.code !== "1000") {
      //   this.$message.error(res.data.msg);
      //   return;
      // }
    },
    addManagement() {
      this.$router.push("/workFirm/addManagement");
    },
    async init() {
      try {
        const res = await getChainJob();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        this.jobList = res.data.data;
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    async handleEdit(item) {
      getJobDetails(item.shop_user_job_id).then((ret) => {
        if (ret.status !== 200 || ret.data.code !== "1000") {
          this.$message.error(ret.data.msg);
          return;
        }
        this.$router.push({
          path: "/workFirm/addManagement",
          query: {
            shop_user_job_id: item.shop_user_job_id,
          },
        });
      });
    },
  },
  mounted() {
    //console.log("mounted");
    this.init();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
  width: 100%;
  height: 140px;
}
/deep/.el-upload {
  width: 100%;
  height: 140px;
}
.firm-management {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .downLoad {
    text-align: center;
    margin-bottom: 20px;
    color: #579eff;
    cursor: pointer;
  }
  .jobPost {
    display: flex;
    margin-bottom: 20px;
  }
  .management-button {
    // background-color: #2a6ee9;
    width: 140px;
    // height: 40px;
    // border-radius: 6px;
    color: #fff;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-weight: bold;
    // cursor: pointer;
    // &:hover {
    //   background-color: #3a7bf3;
    // }
  }
  .import {
    width: 140px;
  }
  .management-table {
    height: 40px;
    font-size: 14px;
    display: flex;
    .table {
      width: 88px;
      height: 26px;
      margin-right: 10px;
      text-align: center;
      line-height: 26px;
      border-radius: 2px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    .is-table {
      background-color: #d4e3ff;
    }
  }
  .management-main {
    height: calc(100% - 90px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .job-list {
      background-color: #fff;
      margin-bottom: 14px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 15px 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .list-main {
        display: flex;
        justify-content: space-between;
        .main-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 22px;
            line-height: 50px;
            font-weight: bold;
          }
          .subhead {
            font-size: 14px;
            line-height: 30px;
          }
        }
        .main-center {
          .is-enable {
            font-size: 18px;
            line-height: 50px;
          }
          .expiration-time {
            font-size: 14px;
            line-height: 30px;
          }
        }
        .main-right {
          display: flex;
          align-items: center;
          justify-content: center;
          .edit-button {
            width: 80px;
            border: none;
            background-color: #f4a933;
            &:hover {
              background-color: #f5b95a;
            }
          }
          .release-button {
            width: 80px;
            border: none;
            background-color: #3e83ff;
            &:hover {
              background-color: #6497f5;
            }
          }
        }
      }
    }
  }
}
</style>
